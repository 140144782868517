import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Container,
  Paper,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  createTenant,
  updateTenant,
  getTenantAPI,
  uploadPaymentImgAPI,
} from "../../api/TenantAPI";
import { getDepositTypes } from "../../api/DepositTypeAPI";
import { getLocationsAPI } from "../../api/DockMasterAPI";

import {
  getPayTypes,
  getSystemIdTokenAPI,
  getAuthTokenAPI,
} from "../../api/DockMasterAPI";
import { getAuthCookies } from "./../../context/CookieManager";

import {
  getCredentialsByClientId,
  createCredential,
  updateCredentialByClientId,
} from "../../api/CredentialsAPI";
import { useNavigate, useParams } from "react-router-dom";

const CreateNewElementDialog = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [clientCredentialInfo, setClientCredentialInfo] = useState({
    ClientId: null,
    DmApiUrl: "",
    DMAPIAuthUrl: "",
    DmApiClerkUsername: "",
    DmApiClerkPassword: "",
    DefaultSystemId: "",
    DMAuthToken: "",
    SystemIdList: [],
    isOldApi: false,
  });

  useEffect(() => {
    getTenantInfo();
  }, []);

  const getFormattedDate = (date) => {
    if (!date) return "";

    const d = new Date(date);
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const getTenantInfo = async () => {
    try {
      const clientId = id;
      if (clientId) {
        const tenantResponse = await getTenantAPI(clientId);
        const credentialsResponse = await getCredentialsByClientId(clientId);

        const tenantData = tenantResponse;
        const credentialsData = credentialsResponse.data[0];
        // Update clientInfo state
        setClientInfo({
          ClientID: tenantData.ClientID,
          DmId: tenantData.DmId,
          ClientName: tenantData.ClientName,
          Address1: tenantData.Address1,
          Address2: tenantData.Address2,
          City: tenantData.City,
          State: tenantData.State,
          Zip: tenantData.Zip,
          Country: tenantData.Country,
          HomeUrl: tenantData.HomeUrl,
          TimeZone: tenantData.TimeZone,
          CurrencyCode: tenantData.CurrencyCode,
          ContactName: tenantData.ContactName,
          Phone: tenantData.Phone,
          MobilePhone: tenantData.MobilePhone,
          Email: tenantData.Email,
          PaymentModule: tenantData.PaymentModule,
          Disabled: tenantData.Disabled,
          DisabledMessage: tenantData.DisabledMessage,
          Inactive: tenantData.Inactive,
          PayTestMode: tenantData.PayTestMode,
          PaymentsEnabled: tenantData.PaymentsEnabled,
          PaymentsDisabledMessage: tenantData.PaymentsDisabledMessage,
          MarkProcessedPayments: tenantData.MarkProcessedPayments,
          AutoPostPaymentBatch: tenantData.AutoPostPaymentBatch,
          PutMiscDepositsOnAccount: tenantData.PutMiscDepositsOnAccount,
          OnAccountLocation: tenantData.OnAccountLocation,
          ApplyCreditsToInvoice: tenantData.ApplyCreditsToInvoice,
        });
        // Update clientCredentialInfo state
        setClientCredentialInfo({
          ClientId: credentialsData.ClientId,
          DmApiUrl: credentialsData.DmApiUrl,
          DMAPIAuthUrl: credentialsData.DMAPIAuthUrl,
          DmApiClerkUsername: credentialsData.DmApiClerkUsername,
          DmApiClerkPassword: credentialsData.DmApiClerkPassword,
          DefaultSystemId: credentialsData.DefaultSystemId,
          DMAuthToken: credentialsData.DMAuthToken,
          SystemIdList: credentialsData.SystemIdList ?? [],
          isOldApi: credentialsData.isOldApi,
        });

        setPaymentModuleInfo({
          PaymentsEnabled: tenantData.PaymentsEnabled,
          PaymentsDisabledMessage: tenantData.PaymentsDisabledMessage,
          MarkProcessedPayments: tenantData.MarkProcessedPayments,
          AutoPostPaymentBatch: tenantData.AutoPostPaymentBatch,
          PutMiscDepositsOnAccount: tenantData.PutMiscDepositsOnAccount,
          OnAccountLocation: tenantData.OnAccountLocation,
          ApplyCreditsToInvoice: tenantData.ApplyCreditsToInvoice,
          PayType: tenantData.PayType,
          DefaultPayLoc: tenantData.DefaultPayLoc,
          NoMiscDeposits: tenantData.NoMiscDeposits,
          PaymentLocationObject: tenantData.PaymentLocationObject,
          DepositTypesObject: tenantData.DepositTypesObject,
        });

        setPaymentModuleCredentialInfo({
          VpMerchantId: credentialsData.VpMerchantId,
          VpApiKey: credentialsData.VpApiKey,
          VpClientKey: credentialsData.VpClientKey,
          VpSubMerchantId: credentialsData.VpSubMerchantId,
          VpStoreId: credentialsData.VpStoreId,
          TestVpStoreId: credentialsData.TestVpStoreId,
        });

        setPaymentForm({
          DepositTypesObject: tenantData.DepositTypesObject ?? [],
          PaymentLocationObject: tenantData.PaymentLocationObject ?? [],
          PaymentFormName: tenantData.PaymentFormName ?? "",
          PaymentFormContactPhone: tenantData.PaymentFormContactPhone ?? "",
          PaymentNotificationEmails: tenantData.PaymentNotificationEmails ?? [],
          VoidRefundFormContactPhone:
            tenantData.VoidRefundFormContactPhone ?? "",
          PaymentContactEmail: tenantData.PaymentContactEmail ?? "",
          BccDepositRequestEmail: tenantData.BccDepositRequestEmail ?? "",
          AcceptedCreditCards: tenantData.AcceptedCreditCards ?? [],
          AcceptAchPayments: tenantData.AcceptAchPayments ?? false,
          RequireBillingAddress: tenantData.RequireBillingAddress ?? false,
          PayOldestInvoiceFirst: tenantData.PayOldestInvoiceFirst ?? false,
          MaximumCcPaymentAmount: tenantData.MaximumCcPaymentAmount ?? "",
          DMConvenienceFeeSurchargeEnabled:
            tenantData.DMConvenienceFeeSurchargeEnabled ?? false,
          ConvenienceFeeSurchargeEnabled:
            tenantData.ConvenienceFeeSurchargeEnabled ?? false,
          ConvenienceFlatFee: tenantData.ConvenienceFlatFee ?? "",
          ConvenienceMessage: tenantData.ConvenienceMessage ?? "",
          SurchargePercent: tenantData.SurchargePercent ?? "",
          SurchargeMaximumFee: tenantData.SurchargeMaximumFee ?? "",
          AchConvenienceFeeSurchargeEnabled:
            tenantData.AchConvenienceFeeSurchargeEnabled ?? false,
          AchConvenienceFlatFee: tenantData.AchConvenienceFlatFee ?? "",
          AchConvenienceMessage: tenantData.AchConvenienceMessage ?? "",
          AchSurchargePercent: tenantData.AchSurchargePercent ?? "",
          AchSurchargeMaximumFee: tenantData.AchSurchargeMaximumFee ?? "",
          PaymentDueDays: tenantData.PaymentDueDays ?? "",
          PastDueWarningOne: {
            enabled: tenantData.PastDueWarningOne?.enabled ?? false,
            days: tenantData.PastDueWarningOne?.days ?? "",
            color: tenantData.PastDueWarningOne?.color ?? "#000000",
            message: tenantData.PastDueWarningOne?.message ?? "",
          },
          PastDueWarningTwo: {
            enabled: tenantData.PastDueWarningTwo?.enabled ?? false,
            days: tenantData.PastDueWarningTwo?.days ?? "",
            color: tenantData.PastDueWarningTwo?.color ?? "#000000",
            message: tenantData.PastDueWarningTwo?.message ?? "",
          },
          PastDueWarningThree: {
            enabled: tenantData.PastDueWarningThree?.enabled ?? false,
            days: tenantData.PastDueWarningThree?.days ?? "",
            color: tenantData.PastDueWarningThree?.color ?? "#000000",
            message: tenantData.PastDueWarningThree?.message ?? "",
          },
        });
        setFileImg(tenantData?.PaymentFormImage ?? null);

        setBillingInfo({
          SendPdfConfirmation: tenantData.SendPdfConfirmation ?? false,
          SendPdfNotification: tenantData.SendPdfNotification ?? false,
          UsePaidRequestTexting: tenantData.UsePaidRequestTexting ?? false,
          PayFirstBillingPeriod: getFormattedDate(
            tenantData.PayFirstBillingPeriod,
          ),
          PaySetupCharge: tenantData.PaySetupCharge ?? 500,
          PayMonthlyCharge: tenantData.PayMonthlyCharge ?? 99,
          PayPremiumTextMonthlyCharge:
            tenantData.PayPremiumTextMonthlyCharge ?? 10,
          PayTransactionCommissionCharge:
            tenantData.PayTransactionCommissionCharge ?? 0.1,
          PayVolumeCommissionPercent:
            tenantData.PayVolumeCommissionPercent ?? 0.005,
        });
      }
    } catch (error) {
      navigate("/page-not-found");
    }
  };

  const [selectedTab, setSelectedTab] = React.useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    message: "",
    severity: "info",
  });

  const [clientInfo, setClientInfo] = useState({
    ClientID: null,
    DmId: "",
    ClientName: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    Zip: "",
    Country: "",
    HomeUrl: "",
    TimeZone: "",
    CurrencyCode: "",
    ContactName: "",
    Phone: "",
    MobilePhone: "",
    Email: "",
    PaymentModule: false,
    Disabled: false,
    DisabledMessage: "",
    Inactive: false,
    PayTestMode: false,
    PaymentsEnabled: true,
    PaymentsDisabledMessage: "",
    MarkProcessedPayments: true,
    AutoPostPaymentBatch: true,
    PutMiscDepositsOnAccount: true,
    OnAccountLocation: "",
    ApplyCreditsToInvoice: true,
  });

  const [paymentModuleInfo, setPaymentModuleInfo] = useState({
    PaymentsEnabled: false,
    PaymentsDisabledMessage: "",
    MarkProcessedPayments: false,
    AutoPostPaymentBatch: false,
    PutMiscDepositsOnAccount: false,
    OnAccountLocation: "",
    ApplyCreditsToInvoice: false,
    PayType: "",
    DefaultPayLoc: "",
    NoMiscDeposits: false,
    PaymentLocationObject: [],
    DepositTypesObject: [],
  });
  const [paymentModuleCredentialInfo, setPaymentModuleCredentialInfo] =
    useState({
      VpMerchantId: "",
      VpApiKey: "",
      VpClientKey: "",
      VpSubMerchantId: "",
      VpStoreId: "",
      TestVpStoreId: "",
    });

  const [fileImg, setFileImg] = useState(null);

  const [paymentForm, setPaymentForm] = useState({
    DepositTypesObject: [],
    PaymentLocationObject: [],
    PaymentFormName: "",
    PaymentFormContactPhone: "",
    PaymentNotificationEmails: [],
    VoidRefundFormContactPhone: "",
    PaymentContactEmail: "",
    BccDepositRequestEmail: "",
    AcceptedCreditCards: [],
    AcceptAchPayments: false,
    RequireBillingAddress: false,
    PayOldestInvoiceFirst: false,
    MaximumCcPaymentAmount: "",
    DMConvenienceFeeSurchargeEnabled: false,
    ConvenienceFeeSurchargeEnabled: false,
    ConvenienceFlatFee: "",
    ConvenienceMessage: "",
    SurchargePercent: "",
    SurchargeMaximumFee: "",
    AchConvenienceFeeSurchargeEnabled: false,
    AchConvenienceFlatFee: "",
    AchConvenienceMessage: "",
    AchSurchargePercent: "",
    AchSurchargeMaximumFee: "",
    PaymentDueDays: "",
    PastDueWarningOne: {
      enabled: false,
      days: "",
      color: "#000000",
      message: "",
    },
    PastDueWarningTwo: {
      enabled: false,
      days: "",
      color: "#000000",
      message: "",
    },
    PastDueWarningThree: {
      enabled: false,
      days: "",
      color: "#000000",
      message: "",
    },
  });

  const [billingInfo, setBillingInfo] = useState({
    SendPdfConfirmation: false,
    SendPdfNotification: false,
    UsePaidRequestTexting: false,
    PayFirstBillingPeriod: "",
    PaySetupCharge: 500,
    PayMonthlyCharge: 99,
    PayPremiumTextMonthlyCharge: 10,
    PayTransactionCommissionCharge: 0.1,
    PayVolumeCommissionPercent: 0.005,
  });

  const [fetchDepositTypesError, setFetchDepositTypesError] = useState("");
  const [locationsError, setLocationsError] = useState("");

  const [paymentTypes, setPaymentTypes] = useState([]);
  const [paymentTypesError, setPaymentTypesError] = useState("");

  const [timeZones, setTimeZones] = useState([]);

  const handleCheckAllDepositTypes = () => {
    setPaymentModuleInfo((prev) => ({
      ...prev,
      DepositTypesObject: prev.DepositTypesObject.map((depositType) => ({
        ...depositType,
        enabled: true, // Set all to checked
      })),
    }));
  };

  const handleUncheckAllDepositTypes = () => {
    setPaymentModuleInfo((prev) => ({
      ...prev,
      DepositTypesObject: prev.DepositTypesObject.map((depositType) => ({
        ...depositType,
        enabled: false, // Set all to unchecked
      })),
    }));
  };

  const fetchDepositTypes = async () => {
    try {
      const response = await getDepositTypes();
      if (response.status_code === 200) {
        if (response.data.length > 0) {
          const depositTypeDetails = response.data.map((depositType) => ({
            id: depositType.id,
            description: depositType.description,
            defLoc: "",
            emails: [],
            enabled: false,
          }));
          setPaymentModuleInfo((prev) => ({
            ...prev,
            DepositTypesObject: depositTypeDetails,
          }));
          setFetchDepositTypesError("");
        } else {
          setFetchDepositTypesError("No deposit types available.");
        }
      } else {
        setFetchDepositTypesError("Failed to fetch deposit types");
      }
    } catch (error) {
      setFetchDepositTypesError(
        "There was a problem fetching the deposit types",
      );
    }
  };

  const fetchLocations = async () => {
    try {
      setLocationsError("");
      const user_id = clientInfo?.ClientID
        ? clientInfo.ClientID
        : getAuthCookies("tenantId");
      const jsonResponse = await getLocationsAPI(user_id);
      if (jsonResponse.length > 0) {
        const structuredLocations = jsonResponse.map((loc) => ({
          Id: loc.id,
          Name: loc.name,
          LocationNumber: loc.locationNumber,
          Address1: loc.address1,
          enabled: false,
        }));
        setPaymentModuleInfo((prevState) => ({
          ...prevState,
          PaymentLocationObject: structuredLocations,
        }));
      } else {
        setLocationsError("No locations available");
      }
    } catch (error) {
      setLocationsError("Error fetching locations: " + error.message);
    }
  };

  const fetchPaymentTypes = async () => {
    try {
      const user_id = clientInfo.ClientID; // Retrieve the client ID used in the form
      const formatted_user_id = `00${user_id}`; // Append "00" to the user ID
      const response = await getPayTypes(formatted_user_id); // Use the formatted user ID

      if (response.status_code === 200 && response.data.length > 0) {
        setPaymentTypes(response.data);
        setPaymentTypesError("");
      } else {
        setPaymentTypesError("No payment types available");
      }
    } catch (error) {
      setPaymentTypesError("Error fetching payment types: " + error.message);
    }
  };

  const fetchTimeZones = async () => {
    try {
      const response = await fetch("https://worldtimeapi.org/api/timezone");
      if (!response.ok) throw new Error("Failed to fetch time zones");
      const data = await response.json();
      setTimeZones(data);
    } catch (error) {
      console.error("Error fetching time zones:", error);
    }
  };

  useEffect(() => {
    if (selectedTab === 0) {
      fetchTimeZones();
    } else if (selectedTab === 1) {
      if (!id) {
        fetchDepositTypes();
        fetchLocations();
      }
      fetchPaymentTypes();
    }
  }, [selectedTab, id]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // All the tabs that I want to render
  const renderClientInformation = () => {
    const handleClientInfoChange = (event) => {
      const { name, value } = event.target;
      setClientInfo({ ...clientInfo, [name]: value });
    };

    const handleClientCredentialInfoChange = (event) => {
      const { name, value, type, checked } = event.target;
      setClientCredentialInfo((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    };

    const handleClientInformationSubmit = async () => {
      try {
        let response; // This will hold the API response.

        if (id) {
          // If there's an ID, we update the existing tenant
          response = await updateTenant({ ...clientInfo, id: id });
        } else {
          // If there's no ID, we create a new tenant
          response = await createTenant(clientInfo);
        }

        if (response.status_code === 200) {
          // Update the state with the new or updated ClientID
          setClientInfo((prevState) => ({
            ...prevState,
            ClientID: response.data.ClientID,
          }));

          // Prepare the credentials with the ClientID
          const newCredential = {
            ...clientCredentialInfo,
            ClientId: response.data.ClientID,
          };

          // Call the createCredential or updateCredential API based on whether it's a new creation or update
          let credentialResponse;
          if (id) {
            credentialResponse = await updateCredentialByClientId(
              response.data.ClientID,
              newCredential,
            );
          } else {
            credentialResponse = await createCredential(newCredential);
            setClientCredentialInfo((prev) => ({
              ...prev,
              ClientId: credentialResponse.data.ClientId,
            }));
          }

          if (credentialResponse.status_code === 200) {
            setSnackbarInfo({
              message: `Tenant and Credential ${
                id ? "update" : "creation"
              } successful`,
              severity: "success",
            });
          } else {
            setSnackbarInfo({
              message: `Tenant ${id ? "updated" : "created"} but Credential ${
                id ? "update" : "creation"
              } failed: ${credentialResponse.message}`,
              severity: "error",
            });
          }
        } else {
          setSnackbarInfo({
            message: `Tenant ${id ? "update" : "creation"} failed: ${
              response.message
            }`,
            severity: "error",
          });
        }
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarInfo({
          message: `Error in processing: ${error.message}`,
          severity: "error",
        });
        setSnackbarOpen(true);
      }
    };

    const handleSwitchChange = (event) => {
      setClientInfo({
        ...clientInfo,
        [event.target.name]: event.target.checked,
      });
    };

    const handleTestDMApiClick = async () => {
      await handleClientInformationSubmit();
      await handleTestApiClick();
    };

    const handleTestApiClick = async () => {
      try {
        const jsonResponse = await getAuthTokenAPI(
          clientCredentialInfo.ClientId,
        );
        setClientCredentialInfo((prev) => ({
          ...prev,
          SystemIdList: jsonResponse.availableConnections ?? [],
        }));
      } catch (error) {
        console.log("err", error);
      }
    };
    const DisabledTestDmApi =
      clientCredentialInfo.ClientId == null ||
      clientCredentialInfo.DmApiUrl == "" ||
      clientCredentialInfo.DMAPIAuthUrl == "" ||
      clientCredentialInfo.DmApiClerkUsername == "" ||
      clientCredentialInfo.DmApiClerkPassword == "";

    return (
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Client ID"
              name="ClientID"
              value={clientInfo.ClientID}
              onChange={handleClientInfoChange}
              margin="normal"
              disabled
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              label="Dockmaster ID"
              name="DmId"
              value={clientInfo.DmId}
              onChange={handleClientInfoChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Client Name"
              name="ClientName"
              value={clientInfo.ClientName}
              onChange={handleClientInfoChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Address 1"
              name="Address1"
              value={clientInfo.Address1}
              onChange={handleClientInfoChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Address 2"
              name="Address2"
              value={clientInfo.Address2}
              onChange={handleClientInfoChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="City"
              name="City"
              value={clientInfo.City}
              onChange={handleClientInfoChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="State"
              name="State"
              value={clientInfo.State}
              onChange={handleClientInfoChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Zip Code"
              name="Zip"
              value={clientInfo.Zip}
              onChange={handleClientInfoChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Country"
              name="Country"
              value={clientInfo.Country}
              onChange={handleClientInfoChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Home Url"
              name="HomeUrl"
              value={clientInfo.HomeUrl}
              onChange={handleClientInfoChange}
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="timeZone-label">Time Zone</InputLabel>
              <Select
                labelId="timeZone-label"
                label="Time Zone"
                name="TimeZone"
                value={clientInfo.TimeZone}
                onChange={handleClientInfoChange}
                disabled={timeZones.length === 0}
              >
                {timeZones.map((zone) => (
                  <MenuItem key={zone} value={zone}>
                    {zone}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                labelId="currency-label"
                label="Currency"
                name="CurrencyCode"
                value={clientInfo.CurrencyCode}
                onChange={handleClientInfoChange}
              >
                <MenuItem value="USD">USD - US Dollar</MenuItem>
                <MenuItem value="CAD">CAD - Canadian Dollar</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Contact Name"
              name="ContactName"
              value={clientInfo.ContactName}
              onChange={handleClientInfoChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Main Phone"
              name="Phone"
              value={clientInfo.Phone}
              onChange={handleClientInfoChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Mobile Phone"
              name="MobilePhone"
              value={clientInfo.MobilePhone}
              onChange={handleClientInfoChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Email Address"
              name="Email"
              value={clientInfo.Email}
              onChange={handleClientInfoChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Dockmaster Auth API Url"
              name="DMAPIAuthUrl"
              sx={{ flexGrow: 1 }}
              value={clientCredentialInfo.DMAPIAuthUrl}
              onChange={handleClientCredentialInfoChange}
              margin="none"
            />
            <Grid container sx={{ my: 2 }}>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  label="Dockmaster API Url"
                  name="DmApiUrl"
                  sx={{ flexGrow: 1 }}
                  value={clientCredentialInfo.DmApiUrl}
                  onChange={handleClientCredentialInfoChange}
                  margin="none"
                />
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ mx: 2 }}>
                  <Button
                    sx={{ m: 0 }}
                    fullWidth
                    variant="outlined"
                    color="primary"
                    disabled={DisabledTestDmApi}
                    onClick={handleTestDMApiClick}
                  >
                    Test DM Api
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <Grid container sx={{ p: 0, m: 0 }}>
              <Grid item xs={12}>
                <FormControl fullWidth margin="none">
                  <InputLabel id="default-system-id-label">
                    Default System ID
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="default-system-id-label"
                    label="Default System ID"
                    sx={{ flexGrow: 1 }}
                    name="DefaultSystemId"
                    value={clientCredentialInfo.DefaultSystemId}
                    onChange={handleClientCredentialInfoChange}
                  >
                    {clientCredentialInfo.SystemIdList.map((system) => (
                      <MenuItem key={system.systemId} value={system.systemId}>
                        {system.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <TextField
              fullWidth
              label="DM Api Clerk"
              name="DmApiClerkUsername"
              value={clientCredentialInfo.DmApiClerkUsername}
              onChange={handleClientCredentialInfoChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="DM Api Clerk Password"
              type="password"
              name="DmApiClerkPassword"
              value={clientCredentialInfo.DmApiClerkPassword}
              onChange={handleClientCredentialInfoChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Client Disabled Message"
              name="DisabledMessage"
              value={clientInfo.DisabledMessage}
              onChange={handleClientInfoChange}
              margin="normal"
              multiline
              rows={4}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={clientInfo.PaymentModule}
                    onChange={handleSwitchChange}
                    name="PaymentModule"
                  />
                }
                label="Payment Module"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={clientInfo.Disabled}
                    onChange={handleSwitchChange}
                    name="Disabled"
                  />
                }
                label="Client Enabled/Disabled"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={clientInfo.Inactive}
                    onChange={handleSwitchChange}
                    name="Inactive"
                  />
                }
                label="Client Active/Inactive"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={clientInfo.PayTestMode}
                    onChange={handleSwitchChange}
                    name="PayTestMode"
                  />
                }
                label="Test Client"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={clientCredentialInfo.isOldApi}
                    onChange={handleClientCredentialInfoChange}
                    name="isOldApi"
                  />
                }
                label="Is Old API"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleClientInformationSubmit}
            >
              Save Client Information
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };
  //----------------------------------//
  const renderPaymentModuleControl = () => {
    const handlePaymentModuleChange = (event) => {
      const { name, value, type, checked } = event.target;
      setPaymentModuleInfo((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    };

    const handlePaymentModuleCredentialChange = (event) => {
      const { name, value } = event.target;
      setPaymentModuleCredentialInfo((prev) => ({
        ...prev,
        [name]: value,
      }));
    };

    const handleSwitchChange = (event) => {
      setPaymentModuleInfo({
        ...paymentModuleInfo,
        [event.target.name]: event.target.checked,
      });
    };

    const handlePaymentModuleInformationSubmit = async () => {
      // Assume you have clientId already available in your paymentModuleInfo state
      const clientId = clientInfo.ClientID;

      try {
        // Update Tenant Information
        const updateTenantResponse = await updateTenant({
          ...paymentModuleInfo,
          id: clientId,
        });
        if (updateTenantResponse.status_code === 200) {
          // Prepare credential data with clientId
          const updatedCredential = {
            ...paymentModuleCredentialInfo,
          };

          // Update Credential Information
          const updateCredentialResponse = await updateCredentialByClientId(
            clientId,
            updatedCredential,
          );
          if (updateCredentialResponse.status_code === 200) {
            setSnackbarInfo({
              message: "Tenant and Credential update successful",
              severity: "success",
            });
          } else {
            setSnackbarInfo({
              message:
                "Tenant updated but Credential update failed: " +
                updateCredentialResponse.message,
              severity: "error",
            });
          }
        } else {
          setSnackbarInfo({
            message: "Tenant update failed: " + updateTenantResponse.message,
            severity: "error",
          });
        }
      } catch (error) {
        setSnackbarInfo({
          message: "Error in update process: " + error.message,
          severity: "error",
        });
      }
      setSnackbarOpen(true);
    };

    const handleLocationToggle = (locationId) => {
      setPaymentModuleInfo((prevState) => ({
        ...prevState,
        PaymentLocationObject: prevState.PaymentLocationObject.map((location) =>
          location.Id === locationId
            ? { ...location, enabled: !location.enabled }
            : location,
        ),
      }));
    };

    const handleToggleDepositType = (id) => {
      setPaymentModuleInfo((prev) => ({
        ...prev,
        DepositTypesObject: prev.DepositTypesObject.map((item) =>
          item.id === id ? { ...item, enabled: !item.enabled } : item,
        ),
      }));
    };

    const handleUpdateDepositType = (id, field, value) => {
      setPaymentModuleInfo((prev) => ({
        ...prev,
        DepositTypesObject: prev.DepositTypesObject.map((item) =>
          item.id === id ? { ...item, [field]: value } : item,
        ),
      }));
    };

    return (
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2}>
          {/* Left column */}
          <Grid item xs={12} sm={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="PaymentsEnabled"
                    checked={paymentModuleInfo.PaymentsEnabled}
                    onChange={handlePaymentModuleChange}
                  />
                }
                label="Payments Enabled/Disabled"
              />
              <TextField
                fullWidth
                label="Payments Disabled Message"
                margin="normal"
                name="PaymentsDisabledMessage"
                value={paymentModuleInfo.PaymentsDisabledMessage}
                onChange={handlePaymentModuleChange}
              />
              {/* <FormControlLabel
                control={
                  <Switch
                    name="MarkProcessedPayments"
                    checked={paymentModuleInfo.MarkProcessedPayments}
                    onChange={handlePaymentModuleChange}
                  />
                }
                label="Mark Processed Payments"
              /> */}
              <FormControlLabel
                control={
                  <Switch
                    name="AutoPostPaymentBatch"
                    checked={paymentModuleInfo.AutoPostPaymentBatch}
                    onChange={handlePaymentModuleChange}
                  />
                }
                label="Auto Post Payment Batch"
              />
              <FormControlLabel
                control={
                  <Switch
                    name="PutMiscDepositsOnAccount"
                    checked={paymentModuleInfo.PutMiscDepositsOnAccount}
                    onChange={handlePaymentModuleChange}
                  />
                }
                label="Put Misc Deposits on Account"
              />
              <FormControlLabel
                control={
                  <Switch
                    name="ApplyCreditsToInvoice"
                    checked={paymentModuleInfo.ApplyCreditsToInvoice}
                    onChange={handlePaymentModuleChange}
                  />
                }
                label="Apply Credits to Invoices"
              />
              <TextField
                fullWidth
                label="ValPay Merchant ID"
                margin="normal"
                name="VpMerchantId"
                value={paymentModuleCredentialInfo.VpMerchantId}
                onChange={handlePaymentModuleCredentialChange}
              />
              <TextField
                fullWidth
                label="ValPay API Key"
                margin="normal"
                name="VpApiKey"
                value={paymentModuleCredentialInfo.VpApiKey}
                onChange={handlePaymentModuleCredentialChange}
              />
              <TextField
                fullWidth
                label="ValPay Client Key"
                margin="normal"
                name="VpClientKey"
                value={paymentModuleCredentialInfo.VpClientKey}
                onChange={handlePaymentModuleCredentialChange}
              />
              <TextField
                fullWidth
                label="ValPay Sub-Merchant ID"
                margin="normal"
                name="VpSubMerchantId"
                value={paymentModuleCredentialInfo.VpSubMerchantId}
                onChange={handlePaymentModuleCredentialChange}
              />
              <TextField
                fullWidth
                label="ValPay Store ID"
                margin="normal"
                name="VpStoreId"
                value={paymentModuleCredentialInfo.VpStoreId}
                onChange={handlePaymentModuleCredentialChange}
              />
              <TextField
                fullWidth
                label="Test ValPay Store ID"
                margin="normal"
                name="TestVpStoreId"
                value={paymentModuleCredentialInfo.TestVpStoreId}
                onChange={handlePaymentModuleCredentialChange}
              />
            </FormGroup>
          </Grid>
          {/* Placeholder for right column */}
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
                Payment Location(s)
              </Typography>
            </Box>
            <Button variant="outlined" color="primary" onClick={fetchLocations}>
              Refresh Locations
            </Button>
            <FormGroup>
              {locationsError ? (
                <Typography color="error">{locationsError}</Typography>
              ) : (
                paymentModuleInfo?.PaymentLocationObject?.map((location) => (
                  <FormControlLabel
                    key={location.Id}
                    control={
                      <Checkbox
                        checked={location.enabled}
                        onChange={() => handleLocationToggle(location.Id)}
                        value={location.Id}
                      />
                    }
                    label={`${location.Id} - ${location.Name} (${location.LocationNumber}, ${location.Address1})`}
                  />
                ))
              )}
            </FormGroup>
            <FormControl fullWidth margin="normal">
              <InputLabel id="payment-type-label">Payment Type</InputLabel>
              <Select
                labelId="payment-type-label"
                label="Payment Type"
                name="PayType"
                value={paymentModuleInfo.PayType} // Ensure you manage this state somewhere in your component
                onChange={handlePaymentModuleChange} // Handle changes accordingly
              >
                {paymentTypesError ? (
                  <MenuItem disabled>{paymentTypesError}</MenuItem>
                ) : (
                  paymentTypes.map((payType) => (
                    <MenuItem key={payType.id} value={payType.id}>
                      {payType.id} - {payType.description}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="default-pay-location-label">
                Def Pay Location
              </InputLabel>
              <Select
                labelId="default-pay-location-label"
                label="Def Pay Location"
                name="DefaultPayLoc"
                value={paymentModuleInfo.DefaultPayLoc} // Ensure this state is managed properly
                onChange={handlePaymentModuleChange} // Implement this function to handle changes
              >
                {locationsError ? (
                  <MenuItem disabled>{locationsError}</MenuItem>
                ) : (
                  paymentModuleInfo?.PaymentLocationObject?.filter(
                    (record) => record.enabled,
                  ).map((location) => (
                    <MenuItem key={location.Id} value={location.Id}>
                      {`${location.Id} - ${location.Name}`}
                    </MenuItem>
                  )) ?? <MenuItem disabled>No locations available</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={paymentModuleInfo.NoMiscDeposits}
                  name="NoMiscDeposits"
                  onChange={handleSwitchChange}
                />
              }
              label="No Misc Deposits Allowed"
            />

            <Box sx={{ width: "50%" }} display={"flex"}>
              <Button
                variant="outlined"
                sx={{ width: "50%", mr: 0.5 }}
                onClick={handleCheckAllDepositTypes}
              >
                Check All
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "50%", ml: 0.5 }}
                onClick={handleUncheckAllDepositTypes}
              >
                Check None
              </Button>
            </Box>
            <Box sx={{ width: "50%" }}>
              <Button
                sx={{ my: 1 }}
                fullWidth
                variant="outlined"
                color="primary"
                onClick={fetchDepositTypes}
              >
                Refresh Deposit Types
              </Button>
            </Box>

            <Box
              sx={{
                border: "1px solid",
                borderColor: "divider",
                p: 2,
                mt: 2,
                maxHeight: "780px",
                overflow: "auto",
              }}
            >
              {fetchDepositTypesError ? (
                <Typography color="error">{fetchDepositTypesError}</Typography>
              ) : (
                paymentModuleInfo?.DepositTypesObject?.map(
                  (depositType, index) => (
                    <FormGroup key={depositType.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={depositType.enabled}
                            onChange={() =>
                              handleToggleDepositType(depositType.id)
                            }
                          />
                        }
                        label={`${depositType.description} (${depositType.id})`}
                      />
                      <TextField
                        sx={{ mb: 2 }}
                        fullWidth
                        label="Default Location"
                        variant="outlined"
                        size="small"
                        value={depositType.defLoc}
                        onChange={(e) =>
                          handleUpdateDepositType(
                            depositType.id,
                            "defLoc",
                            e.target.value,
                          )
                        }
                      />
                      <TextField
                        fullWidth
                        label="Email(s)"
                        variant="outlined"
                        size="small"
                        value={depositType.emails.join(",")}
                        onChange={(e) =>
                          handleUpdateDepositType(
                            depositType.id,
                            "emails",
                            e.target.value.split(","),
                          )
                        }
                      />
                    </FormGroup>
                  ),
                ) ?? <Typography>No deposit types available.</Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handlePaymentModuleInformationSubmit}
            >
              Save Payment Module Information
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };
  //----------------------------------//
  const renderPaymentFormControl = () => {
    const handleChangeCheckbox = (event, value) => {
      setPaymentForm((prev) => ({
        ...prev,
        // in case exist in array remove it else add it
        [event.target.name]: prev[event.target.name].includes(value)
          ? prev[event.target.name].filter((item) => item !== value)
          : [...prev[event.target.name], value],
      }));
    };

    const handleInputChange = (event) => {
      const { name, value, type, checked } = event.target;
      if (name.startsWith("PastDueWarning")) {
        const [warning, key] = name.split(".");
        setPaymentForm((prev) => ({
          ...prev,
          [warning]: {
            ...prev[warning],
            [key]: type === "checkbox" ? checked : value,
          },
        }));
      } else {
        setPaymentForm((prev) => ({
          ...prev,
          [name]: type === "checkbox" ? checked : value,
        }));
      }
    };

    const handleArrayChange = (name, value) => {
      setPaymentForm((prev) => ({
        ...prev,
        [name]: value.split(",").map((item) => item.trim()), // Assuming comma-separated input for emails and credit cards
      }));
    };

    const handleImageChange = (event) => {
      if (event.target.files && event.target.files[0]) {
        // Assuming you want to store only the file name in the state
        const file = event.target.files[0];

        setFileImg(file);
      }
    };

    const handleSubmit = async () => {
      // Serialize JSON fields and prepare the rest of the data
      const formData = {
        ...paymentForm,
        ClientID: clientInfo.ClientID, // Ensure ClientID is correctly passed as a direct field
        PastDueWarningOne: paymentForm.PastDueWarningOne,
        PastDueWarningTwo: paymentForm.PastDueWarningTwo,
        PastDueWarningThree: paymentForm.PastDueWarningThree,
      };

      // Remove empty strings for float fields to prevent parsing errors
      const floatFields = [
        "AchConvenienceFlatFee",
        "AchSurchargePercent",
        "AchSurchargeMaximumFee",
        "PaymentDueDays",
        "MaximumCcPaymentAmount",
        "ConvenienceFlatFee",
        "SurchargePercent",
        "SurchargeMaximumFee",
        "AchConvenienceFlatFee",
        "AchSurchargePercent",
        "AchSurchargeMaximumFee",
      ];
      floatFields.forEach((field) => {
        if (formData[field] === "") {
          delete formData[field];
        }
      });

      try {
        if (fileImg) {
          const imgResponse = await uploadPaymentImgAPI(
            clientInfo.ClientID,
            fileImg,
          );
        }

        // Make sure to structure the request correctly as expected by your backend
        const updateResponse = await updateTenant({
          ...formData,
          id: clientInfo.ClientID,
        }); // Passing ClientID inside the payload if necessary
        if (updateResponse.status_code === 200) {
          setSnackbarInfo({
            message: "Payment form updated successfully!",
            severity: "success",
          });
        } else {
          setSnackbarInfo({
            message: `Update failed: ${updateResponse.message}`,
            severity: "error",
          });
        }
      } catch (error) {
        setSnackbarInfo({
          message: `Error: ${error.message}`,
          severity: "error",
        });
      }
      setSnackbarOpen(true);
    };

    return (
      <Box sx={{ p: 3 }}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            {/* Payment Form and Contact Information */}
            <TextField
              fullWidth
              label="Payment Form Name"
              name="PaymentFormName"
              value={paymentForm.PaymentFormName}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Payment Form Contact Phone"
              name="PaymentFormContactPhone"
              value={paymentForm.PaymentFormContactPhone}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Payment Notification Email(s)"
              name="PaymentNotificationEmails"
              value={paymentForm.PaymentNotificationEmails.join(",")}
              onChange={(e) =>
                handleArrayChange("PaymentNotificationEmails", e.target.value)
              }
              margin="normal"
              helperText="Enter emails separated by commas"
            />
            <TextField
              fullWidth
              label="Void/Refund Form Contact Phone"
              name="VoidRefundFormContactPhone"
              value={paymentForm.VoidRefundFormContactPhone}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Payment Contact Email"
              name="PaymentContactEmail"
              value={paymentForm.PaymentContactEmail}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Bcc Deposit Request Email"
              name="BccDepositRequestEmail"
              value={paymentForm.BccDepositRequestEmail}
              onChange={handleInputChange}
              margin="normal"
            />

            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant={"body1"}>Accepted Credit Cards</Typography>

              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="AcceptedCreditCards"
                      checked={paymentForm.AcceptedCreditCards.includes("A")}
                      onChange={(e) => handleChangeCheckbox(e, "A")}
                    />
                  }
                  label="Amex"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="AcceptedCreditCards"
                      checked={paymentForm.AcceptedCreditCards.includes("D")}
                      onChange={(e) => handleChangeCheckbox(e, "D")}
                    />
                  }
                  label="Discover"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="AcceptedCreditCards"
                      checked={paymentForm.AcceptedCreditCards.includes("M")}
                      onChange={(e) => handleChangeCheckbox(e, "M")}
                    />
                  }
                  label="Mastercard"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="AcceptedCreditCards"
                      checked={paymentForm.AcceptedCreditCards.includes("V")}
                      onChange={(e) => handleChangeCheckbox(e, "V")}
                    />
                  }
                  label="Visa"
                />
              </Box>
            </Box>

            {/* Payment Options */}
            <FormControlLabel
              control={
                <Switch
                  checked={paymentForm.AcceptAchPayments}
                  onChange={handleInputChange}
                  name="AcceptAchPayments"
                />
              }
              label="Accept eCheck (ACH) Payments"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={paymentForm.RequireBillingAddress}
                  onChange={handleInputChange}
                  name="RequireBillingAddress"
                />
              }
              label="Require Billing Address (VP)"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={paymentForm.PayOldestInvoiceFirst}
                  onChange={handleInputChange}
                  name="PayOldestInvoiceFirst"
                />
              }
              label="Pay Oldest Invoice First"
            />
            <TextField
              fullWidth
              label="Maximum CC Payment Amount"
              name="MaximumCcPaymentAmount"
              value={paymentForm.MaximumCcPaymentAmount}
              onChange={handleInputChange}
              margin="normal"
              type="number"
            />

            {/* Fees and Surcharges */}
            <FormControlLabel
              control={
                <Switch
                  checked={paymentForm.DMConvenienceFeeSurchargeEnabled}
                  onChange={handleInputChange}
                  name="DMConvenienceFeeSurchargeEnabled"
                />
              }
              label="DM Convenience Fee/Surcharge Enabled"
            />
            <TextField
              fullWidth
              label="Convenience Flat Fee"
              name="ConvenienceFlatFee"
              value={paymentForm.ConvenienceFlatFee}
              onChange={handleInputChange}
              margin="normal"
              type="number"
            />
            <TextField
              fullWidth
              label="Convenience Message"
              name="ConvenienceMessage"
              value={paymentForm.ConvenienceMessage}
              onChange={handleInputChange}
              margin="normal"
              multiline
              rows={4}
            />
            <TextField
              fullWidth
              label="Surcharge Percent"
              name="SurchargePercent"
              value={paymentForm.SurchargePercent}
              onChange={handleInputChange}
              margin="normal"
              type="number"
            />
            <TextField
              fullWidth
              label="Surcharge Maximum Fee"
              name="SurchargeMaximumFee"
              value={paymentForm.SurchargeMaximumFee}
              onChange={handleInputChange}
              margin="normal"
              type="number"
            />

            {/* ACH Surcharges */}
            <FormControlLabel
              control={
                <Switch
                  checked={paymentForm.AchConvenienceFeeSurchargeEnabled}
                  onChange={handleInputChange}
                  name="AchConvenienceFeeSurchargeEnabled"
                />
              }
              label="ACH Convenience Fee/Surcharge Enabled"
            />
            <TextField
              fullWidth
              label="ACH Convenience Flat Fee"
              name="AchConvenienceFlatFee"
              value={paymentForm.AchConvenienceFlatFee}
              onChange={handleInputChange}
              margin="normal"
              type="number"
            />
            <TextField
              fullWidth
              label="ACH Convenience Message"
              name="AchConvenienceMessage"
              value={paymentForm.AchConvenienceMessage}
              onChange={handleInputChange}
              margin="normal"
              multiline
              rows={4}
            />
            <TextField
              fullWidth
              label="ACH Surcharge Percent"
              name="AchSurchargePercent"
              value={paymentForm.AchSurchargePercent}
              onChange={handleInputChange}
              margin="normal"
              type="number"
            />
            <TextField
              fullWidth
              label="ACH Surcharge Maximum Fee"
              name="AchSurchargeMaximumFee"
              value={paymentForm.AchSurchargeMaximumFee}
              onChange={handleInputChange}
              margin="normal"
              type="number"
            />

            {/* Payment Due Days */}
            <TextField
              fullWidth
              label="Payment Due Days"
              name="PaymentDueDays"
              value={paymentForm.PaymentDueDays}
              onChange={handleInputChange}
              margin="normal"
              type="number"
            />

            <Grid item xs={12} sm={4}>
              <Typography variant="h6">Past Due Warning 1</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={paymentForm.PastDueWarningOne.enabled}
                    onChange={handleInputChange}
                    name="PastDueWarningOne.enabled"
                  />
                }
                label="Enabled"
              />
              <TextField
                fullWidth
                type="number"
                label="Days"
                name="PastDueWarningOne.days"
                value={paymentForm.PastDueWarningOne.days}
                onChange={handleInputChange}
              />
              <input
                type="color"
                name="PastDueWarningOne.color"
                value={paymentForm.PastDueWarningOne.color}
                onChange={handleInputChange}
                style={{ margin: "10px 0" }}
              />
              <TextField
                fullWidth
                label="Message"
                name="PastDueWarningOne.message"
                value={paymentForm.PastDueWarningOne.message}
                onChange={handleInputChange}
                multiline
                rows={4}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="h6">Past Due Warning 2</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={paymentForm.PastDueWarningTwo.enabled}
                    onChange={handleInputChange}
                    name="PastDueWarningTwo.enabled"
                  />
                }
                label="Enabled"
              />
              <TextField
                fullWidth
                type="number"
                label="Days"
                name="PastDueWarningTwo.days"
                value={paymentForm.PastDueWarningTwo.days}
                onChange={handleInputChange}
              />
              <input
                type="color"
                name="PastDueWarningTwo.color"
                value={paymentForm.PastDueWarningTwo.color}
                onChange={handleInputChange}
                style={{ margin: "10px 0" }}
              />
              <TextField
                fullWidth
                label="Message"
                name="PastDueWarningTwo.message"
                value={paymentForm.PastDueWarningTwo.message}
                onChange={handleInputChange}
                multiline
                rows={4}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="h6">Past Due Warning 3</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={paymentForm.PastDueWarningThree.enabled}
                    onChange={handleInputChange}
                    name="PastDueWarningThree.enabled"
                  />
                }
                label="Enabled"
              />
              <TextField
                fullWidth
                type="number"
                label="Days"
                name="PastDueWarningThree.days"
                value={paymentForm.PastDueWarningThree.days}
                onChange={handleInputChange}
              />
              <input
                type="color"
                name="PastDueWarningThree.color"
                value={paymentForm.PastDueWarningThree.color}
                onChange={handleInputChange}
                style={{ margin: "10px 0" }}
              />
              <TextField
                fullWidth
                label="Message"
                name="PastDueWarningThree.message"
                value={paymentForm.PastDueWarningThree.message}
                onChange={handleInputChange}
                multiline
                rows={4}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Payment Form Image</Typography>
              <Button variant="contained" component="label">
                Choose File
                <input type="file" hidden onChange={handleImageChange} />
              </Button>
              {fileImg !== null && (
                <Box mt={2} sx={{ border: "1px solid #ccc", padding: 2 }}>
                  <Typography variant="caption" display="block">
                    Preview:
                  </Typography>
                  <img
                    src={
                      fileImg instanceof File
                        ? URL.createObjectURL(fileImg)
                        : fileImg
                    }
                    alt="Preview"
                    style={{ width: "100%", maxHeight: "300px" }}
                  />
                </Box>
              )}
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
              >
                Save Payment Form Information
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  };
  //----------------------------------//
  const renderPaymentAndBillingInformation = () => {
    // Handler for input changes in form elements
    const handleBillingInfoChange = (event) => {
      const { name, value, type, checked } = event.target;
      setBillingInfo((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    };

    // Submit handler for billing information
    const handleBillingInformationSubmit = async () => {
      try {
        const updateResponse = await updateTenant({
          ...billingInfo,
          id: clientInfo.ClientID,
        });
        if (updateResponse.status_code === 200) {
          setSnackbarInfo({
            message: "Billing information update successful",
            severity: "success",
          });
        } else {
          setSnackbarInfo({
            message: `Billing update failed: ${updateResponse.message}`,
            severity: "error",
          });
        }
      } catch (error) {
        setSnackbarInfo({
          message: `Error in billing update process: ${error.message}`,
          severity: "error",
        });
      }
      setSnackbarOpen(true);
    };

    return (
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={billingInfo.SendPdfConfirmation}
                    onChange={handleBillingInfoChange}
                    name="SendPdfConfirmation"
                  />
                }
                label="Send PDF Confirmation"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={billingInfo.SendPdfNotification}
                    onChange={handleBillingInfoChange}
                    name="SendPdfNotification"
                  />
                }
                label="Send PDF Notification"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={billingInfo.UsePaidRequestTexting}
                    onChange={handleBillingInfoChange}
                    name="UsePaidRequestTexting"
                  />
                }
                label="Use Paid Request Texting (A $10 charge applies)"
              />
            </FormGroup>
            <TextField
              fullWidth
              label="Pay First Billing Period (YYYY-MM-DD)"
              margin="normal"
              name="PayFirstBillingPeriod"
              value={billingInfo.PayFirstBillingPeriod}
              onChange={handleBillingInfoChange}
              type="date"
              InputLabelProps={{
                shrink: true, // This helps to correctly position the label when the input type is 'date'
              }}
            />
            <TextField
              fullWidth
              label="Pay Setup Charge (default $500)"
              defaultValue="500"
              margin="normal"
              name="PaySetupCharge"
              value={billingInfo.PaySetupCharge}
              onChange={handleBillingInfoChange}
              type="number"
            />
            <TextField
              fullWidth
              label="Pay Monthly Charge (default $99)"
              defaultValue="99"
              margin="normal"
              name="PayMonthlyCharge"
              value={billingInfo.PayMonthlyCharge}
              onChange={handleBillingInfoChange}
              type="number"
            />
            <TextField
              fullWidth
              label="Pay Premium Text Monthly Charge (default $10)"
              defaultValue="10"
              margin="normal"
              name="PayPremiumTextMonthlyCharge"
              value={billingInfo.PayPremiumTextMonthlyCharge}
              onChange={handleBillingInfoChange}
              type="number"
            />
            <TextField
              fullWidth
              label="Pay Transaction Commission Charge (default $0.10)"
              defaultValue="0.1"
              margin="normal"
              name="PayTransactionCommissionCharge"
              value={billingInfo.PayTransactionCommissionCharge}
              onChange={handleBillingInfoChange}
              type="number"
            />
            <TextField
              fullWidth
              label="Pay Volume Commission Percent (default 0.5%)"
              defaultValue="0.005"
              margin="normal"
              name="PayVolumeCommissionPercent"
              value={billingInfo.PayVolumeCommissionPercent}
              onChange={handleBillingInfoChange}
              type="number"
            />
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleBillingInformationSubmit}
              >
                Save Billing Information
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  // --------------------------------//

  return (
    <Paper
      elevation={3}
      sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" textAlign="center" gutterBottom>
          Create New Client
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4 }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            centered
            aria-label="client form tabs"
          >
            <Tab label="Client Information" />
            <Tab label="Payment Module Control" />
            <Tab label="Payment Form Control" />
            <Tab label="Payment And Billing Information" />
          </Tabs>
        </Box>
        {selectedTab === 0 && renderClientInformation()}
        {selectedTab === 1 && renderPaymentModuleControl()}
        {selectedTab === 2 && renderPaymentFormControl()}
        {selectedTab === 3 && renderPaymentAndBillingInformation()}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarInfo.severity}
            sx={{ width: "100%" }}
          >
            {snackbarInfo.message}
          </Alert>
        </Snackbar>
      </Container>
    </Paper>
  );
};

export default CreateNewElementDialog;
